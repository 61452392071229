//  gmdv object borns here
//  Main values
let gmdv = {
  // Google Map
  google_API_KEY: `AIzaSyAm2FeTo2glpS7des0xGyOG_SQopi39v44`,
  USACenter: {
    lat: 37.09024,
    lng: -95.71289100000001,
  },
  searchtype: `location`,
  minZoom: 3,
  maxZoom: 18,
  // Brightcove Player
  brightcove: {
    accountId: '5662516413001',
    playerId: 'sMco4U61r',
  },
}

//  Brochure Categories' list Array
gmdv.brochures = [`Brochure`, `Newsletter`]

//  Spas and Names States' list Array
gmdv.statesList = [
  { spa: `AL`, name: `Alabama` },
  { spa: `AK`, name: `Alaska` },
  { spa: `AZ`, name: `Arizona` },
  { spa: `AR`, name: `Arkansas` },
  { spa: `CA`, name: `California` },
  { spa: `CO`, name: `Colorado` },
  { spa: `CT`, name: `Connecticut` },
  { spa: `DE`, name: `Delaware` },
  { spa: `DC`, name: `District Of Columbia` },
  { spa: `FL`, name: `Florida` },
  { spa: `GA`, name: `Georgia` },
  { spa: `HI`, name: `Hawaii` },
  { spa: `ID`, name: `Idaho` },
  { spa: `IL`, name: `Illinois` },
  { spa: `IN`, name: `Indiana` },
  { spa: `IA`, name: `Iowa` },
  { spa: `KS`, name: `Kansas` },
  { spa: `KY`, name: `Kentucky` },
  { spa: `LA`, name: `Louisiana` },
  { spa: `ME`, name: `Maine` },
  { spa: `MD`, name: `Maryland` },
  { spa: `MA`, name: `Massachusetts` },
  { spa: `MI`, name: `Michigan` },
  { spa: `MN`, name: `Minnesota` },
  { spa: `MS`, name: `Mississippi` },
  { spa: `MO`, name: `Missouri` },
  { spa: `MT`, name: `Montana` },
  { spa: `NE`, name: `Nebraska` },
  { spa: `NV`, name: `Nevada` },
  { spa: `NH`, name: `New Hampshire` },
  { spa: `NJ`, name: `New Jersey` },
  { spa: `NM`, name: `New Mexico` },
  { spa: `NY`, name: `New York` },
  { spa: `NC`, name: `North Carolina` },
  { spa: `ND`, name: `North Dakota` },
  { spa: `OH`, name: `Ohio` },
  { spa: `OK`, name: `Oklahoma` },
  { spa: `OR`, name: `Oregon` },
  { spa: `PA`, name: `Pennsylvania` },
  { spa: `RI`, name: `Rhode Island` },
  { spa: `SC`, name: `South Carolina` },
  { spa: `SD`, name: `South Dakota` },
  { spa: `TN`, name: `Tennessee` },
  { spa: `TX`, name: `Texas` },
  { spa: `UT`, name: `Utah` },
  { spa: `VT`, name: `Vermont` },
  { spa: `VA`, name: `Virginia` },
  { spa: `WA`, name: `Washington` },
  { spa: `WV`, name: `West Virginia` },
  { spa: `WI`, name: `Wisconsin` },
  { spa: `WY`, name: `Wyoming` },
]

/***    Fuctions    ***/

/***    Fuctions about Main Values    ***/
// Function to create googleMapURL value
gmdv.googleMapURL = () => {
  return `https://maps.googleapis.com/maps/api/js?key=${gmdv.google_API_KEY}&v=3.exp&libraries=geometry,drawing,places`
}

// Function to create Brightcove string
gmdv.getBcString = (videoId) => {
  let srcValue = `https://players.brightcove.net/${gmdv.brightcove.accountId}`
  srcValue += `/${gmdv.brightcove.playerId}_default`
  srcValue += `/index.html?videoId=${videoId}`
  return srcValue
}

//  Function to add the distances
gmdv.drupalData = (center, data) => {
  {
    console.log('sort')

    data.length
      ? data.map((item) => {
          let distancebw = gmdv.distanceCenter(
            center.lat,
            center.lng,
            item.node.field_latitude,
            item.node.field_longitude,
            'm'
          )
          item.node['distance'] = distancebw
        })
      : null
  }
  const newsortedcenters = data.sort((a, b) =>
    a.node.distance >= b.node.distance ? 1 : -1
  )
  return newsortedcenters
}

gmdv.drupalDataNoSort = (center, data) => {
  {
    //console.log('no sort')
    data.length
      ? data.map((item) => {
          let distancebw = gmdv.distanceCenter(
            center.lat,
            center.lng,
            item.node.field_latitude,
            item.node.field_longitude,
            'm'
          )
          item.node['distance'] = distancebw
        })
      : null
  }
  const newsortedcenters = data
  return newsortedcenters
}

//  Function to calculate the centers distances from the center
gmdv.distanceCenter = (lat1, lon1, lat2, lon2, unit) => {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0
  } else {
    var radlat1 = (Math.PI * lat1) / 180
    var radlat2 = (Math.PI * lat2) / 180
    var theta = lon1 - lon2
    var radtheta = (Math.PI * theta) / 180
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    if (dist > 1) {
      dist = 1
    }
    dist = Math.acos(dist)
    dist = (dist * 180) / Math.PI
    dist = dist * 60 * 1.1515
    if (unit == 'K') {
      dist = dist * 1.609344
    }
    if (unit == 'N') {
      dist = dist * 0.8684
    }
    return dist
  }
}

// Function to turn meters in miles
gmdv.sphericalDistance = (distance) => {
  return distance * 1609.34
}

/***    Functions about featured and linked services   ***/
// Function to turn checkedItems object in an array
gmdv.checkedItemsArrayFunc = (checkedItems) => {
  let checkedItemsArray = []
  for (let key in checkedItems) {
    if (checkedItems.hasOwnProperty(key)) {
      checkedItemsArray.push(key)
    }
  }
  return checkedItemsArray
}

// Function to turn services ids object in an array
gmdv.servicesArrayFunc = (servicesTaxonomy) => {
  let servicesArray = []
  servicesTaxonomy.map((itemServices) => {
    servicesArray.push(itemServices.id)
  })
  return servicesArray
}

/***    Fuctions about brochure' list Array   ***/
// Function to get brochure category
gmdv.selectBrochure = (brochure) => {
  return gmdv.brochures[brochure]
}

/***    Fuctions about States' list Array   ***/
// Function to get states spa and names
gmdv.selectState = (numberState) => {
  return {
    spa: gmdv.statesList[numberState - 1].spa.toString(),
    name: gmdv.statesList[numberState - 1].name.toString(),
  }
}

/***    Gatsby Nodes related functions  ***/
//  Function to set a good url
gmdv.cleanUrl = (string) => {
  string = string.replace(/\s/g, '-')
  return encodeURI(string)
}

//  Function to set releases url
gmdv.urlRelease = (path, category) => {
  let position = ``
  switch (category) {
    case `Press Releases`:
      position = `/about-us/press/press-releases`
      break
    case `Earnings Release`:
      position = `/investor-relations/earnings-release`
      break
    case `Events & Presentations`:
      position = `/investor-relations/events-presentations`
      break
    default:
      position = `/about-us/press/press-releases`
  }
  path = path.replace('/releases', position)
  return path.toLowerCase()
}

//  Get the right menu for a specific page
gmdv.getMenuid = (location) => {
  let menuName = ``
  if (location.pathname) {
    location = location.pathname
  }
  let pathArray = location.split('/')

  //  exceptions
  switch (pathArray[1]) {
    /*case `releases`:
            menuName = `investor-relations`
          break;*/
    default:
      menuName = pathArray[1]
  }

  return menuName
}

//  Get date for Events and News
//  filtering
gmdv.getStartingDate = (yearsAgo = '0', separator = '-') => {
  let date = new Date().getDate()
  let month = new Date().getMonth() + 1
  let year = new Date().getFullYear() - yearsAgo

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date < 10 ? `0${date}` : `${date}`}`
}

//  Get values on the search
//  Trim body correctly
gmdv.searchSizeFilter = (str) => {
  let length = 300
  if (str === null || str === '') {
    return null
  } else {
    str = str.toString().replace(/<[^>]*>/g, '')
    //str = str.toString().replace(/\b[^\sA-Z][a-zA-Z]{0,3}\b/g, '')
    str = (str.match(RegExp('.{' + length + '}\\S*')) || [str])[0]
    return str
  }
}

gmdv.getSearchValue = (field) => {
  return field ? field : null
}

gmdv.getSearchProcessed = (field) => {
  return field ? field.processed : null
}

//  date showed
gmdv.dateProcessed = (fullDate) => {
  fullDate = `${fullDate} 20:00:00 GMT-0000`
  let dateString = new Date(fullDate)

  // In case its IOS, parse the fulldate parts and re-create the date object.
  if (Number.isNaN(dateString.getMonth())) {
    let arr = fullDate.split(/[- :]/)
    dateString = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
  }

  return dateString

  /***    interested pages   ***
    *   contenttype level
        src\templates\contenttypes\happening_news.js
        src\templates\contenttypes\happening_event.js
        src\templates\contenttypes\corp_resources_list.js
        src\templates\contenttypes\corp_happenings_list.js

        *   Releases level
            src\templates\contenttypes\releases\pressreleases.js
            src\templates\contenttypes\releases\eventspresentations.js
            src\templates\contenttypes\releases\earningsrelease.js
      
    *   components level
        src\components\happenings\events.js
        src\components\happenings\news.js
        src\components\releases\releases.js
        src\components\resources\resources.js
    */
}

/**
 * Links Menus List
 * src\components\menus\navbarmenu.js
 * src\components\menus\teamnavbarmenu.js
 * src\components\menus\boardnavbarmenu.js
 */

module.exports = gmdv
