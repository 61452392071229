import { graphql, useStaticQuery } from "gatsby";

import React from 'react';

const Leftmenu = (props) => {

    const data = useStaticQuery(graphql`{
        allMenuLinkContentMenuLinkContent(sort: {fields: [weight], order: ASC}, filter: {enabled: {eq: true}}) {
            edges {
                node {
                    drupal_id
                    title
                    link {
                        uri
                        title
                    }
                    drupal_parent_menu_item
                    menu_name
                    weight
                    enabled
                }
            }
            totalCount
        }
    }`)

    let menuData = data.allMenuLinkContentMenuLinkContent.edges
    menuData = menuData.filter(item => {return item.node.menu_name.trim() ===  props.menuName.trim()});

    let menuArray = []
    
    menuData.map((itemNode) => {
        let item = itemNode.node
        let drupalIdValue = item.drupal_id
        let refidValue = item.drupal_id
        let titleValue = item.title.trim()

        let linkValue = item.link.uri.replace('internal:', '')
        let linkParentValue = linkValue
        let positionValue = `parent`
        
        if(item.drupal_parent_menu_item != null) {
            refidValue = item.drupal_parent_menu_item.replace('menu_link_content:', '')
            positionValue = `children`
            menuData.forEach((idNode) => {
                if(refidValue === idNode.node.drupal_id) {
                    linkParentValue = idNode.node.link.uri.replace('internal:', '');
                }
            });
        }

        menuArray.push({
            drupalId : drupalIdValue,
            refid : refidValue,
            title : titleValue,
            link : linkValue,
            linkParent : linkParentValue,
            position: positionValue,
        })
    })

    let renderResultValue = (
    <ul>
        {menuArray.map((menuItem, key) => {
            if (menuItem.position === `parent`) {
                return (
                    <li key={key}>
                        <a href={`${menuItem.link.toLowerCase()}`}>{menuItem.title}</a>
                    </li>
                )
            }
        })}
    </ul>)

    return (renderResultValue);
}

export default Leftmenu;